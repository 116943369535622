import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'
import TDSPolicyRummy from '../components/TDSPolicy/TDSPolicyRummy'
import { RUMMY_TDS } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import Layout from '../components/layout'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { rummyTDSFaqs } from '../components/FAQs/faqs-list'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Rummy TDS Policy',
    url: RUMMY_TDS,
  },
]

const TDSRummy: React.FC = () => (
  <Layout
    showBreadcrumbs
    breadcrumbs={breadcrumbs}
    showRummyForm
    rummyOneLink={rummyOneLinksStaticPage}
    pokerOneLink={pokerOneLinksStaticPage}
  >
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Mega Rummy TDS Policy"
      description="Read about the rummy tds policy at Mega. Get to know about data collection, usage, and protection by Mega. Click to know more!"
      organizationSchema={generateOrgSchema()}
      websiteSchema={generateWebsiteSchema()}
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      faqSchema={generateFaqSchema(rummyTDSFaqs)}
    />
    <TDSPolicyRummy />
  </Layout>
)

export default TDSRummy
